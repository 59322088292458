export default class User {
    public id?: number;
    public adresseAgent?: string;
    public boutique?: string;
    public contactAgent?: string;
    public dateNaisAgent?: string;
    public droit?: Record<string, any>;
    public nomAgent: string; 
    public prenomAgent: string;
    public profil?: string;
    public pseudoAgent?: string;
    public sexeAgent?: string; 
    public passwordAgent?: string;
    public photoProfil?: string;

    public codeAgent?: string;
    public paysAgent?: string;
    public contactMobile?: string;
    public contactFixe?: string;
    public dteNais?: string;
    public dteDebService?: string;
    public dteFinService?: string;
    public idTypeAgent?: string;
    public actif?: boolean;
    public enssured?: boolean;
    public newsletter?: boolean;
    public methodRecepSalireId?: string;
    public numComptSalaire?: string;
    public shopId?: string;
    public dossierGalId?: string;
    public cassierJudiciaire?: string;
    public idTypePieceIdentite?: string;
    public numPieceIdentite?: string;
    public pieceIdentite?: string;


    static fromJson(parsedJson: Record<string, any>): User {
        return new User(
            Number(parsedJson['id']),
            parsedJson['adresseAgent'],
            parsedJson['boutique'],
            parsedJson['contactAgent'],
            parsedJson['dateNaisAgent'],
            parsedJson['droit'],
            parsedJson['nomAgent'],
            parsedJson['prenomAgent'],
            parsedJson['profil'],
            parsedJson['pseudoAgent'],
            parsedJson['sexeAgent'],
            parsedJson['passwordAgent'],
            parsedJson['photoProfil'],
            parsedJson['codeAgent'],
            parsedJson['paysAgent'],
            parsedJson['contactMobile'],
            parsedJson['contactFixe'],
            parsedJson['dteNais'],
            parsedJson['dteDebService'],
            parsedJson['dteFinService'],
            parsedJson['idTypeAgent'],
            parsedJson['actif'],
            parsedJson['enssured'],
            parsedJson['newsletter'],
            parsedJson['methodRecepSalireId'],
            parsedJson['numComptSalaire'],
            parsedJson['shopId'],
            parsedJson['dossierGalId'],
            parsedJson['cassierJudiciaire'],
            parsedJson['idTypePieceIdentite'],
            parsedJson['numPieceIdentite'],
            parsedJson['pieceIdentite']
        );
    }

    static toJson(user: User): Record<string, any>  {
        return  {
            "id": user.id,
            "adresseAgent": user.adresseAgent,
            "boutique": user.boutique,
            "contactAgent": user.contactAgent,
            "dateNaisAgent": user.dateNaisAgent,
            "droit": user.droit,
            "nomAgent": user.nomAgent,
            "prenomAgent": user.prenomAgent,
            "profil": user.profil,
            "pseudoAgent": user.pseudoAgent,
            "sexeAgent": user.sexeAgent,
            "passwordAgent": user.passwordAgent,
            "photoProfil": user.photoProfil,
            "codeAgent": user.codeAgent,
            "paysAgent": user.paysAgent,
            "contactMobile": user.contactMobile,
            "contactFixe": user.contactFixe,
            "dteNais": user.dteNais,
            "dteDebService": user.dteDebService,
            "dteFinService": user.dteFinService,
            "idTypeAgent": user.idTypeAgent,
            "actif": user.actif,
            "enssured": user.enssured,
            "newsletter": user.newsletter,
            "methodRecepSalireId": user.methodRecepSalireId,
            "numComptSalaire": user.numComptSalaire,
            "shopId": user.shopId,
            "dossierGalId": user.dossierGalId,
            "cassierJudiciaire": user.cassierJudiciaire,
            "idTypePieceIdentite": user.idTypePieceIdentite,
            "numPieceIdentite": user.numPieceIdentite,
            "pieceIdentite": user.pieceIdentite
        };
    }
  
    constructor(id: number, adresseAgent: string, boutique: string, contactAgent: string , 
        dateNaisAgent: string, droit: Record<string, any>, nomAgent: string, prenomAgent: string, profil: string, 
        pseudoAgent: string, sexeAgent: string, passwordAgent: string, photoProfil: string,
        codeAgent?: string, paysAgent?: string, contactMobile?: string, contactFixe?: string, 
        dteNais?: string, dteDebService?: string, dteFinService?: string, idTypeAgent?: string, actif?: boolean, 
        enssured?: boolean, newsletter?: boolean,  methodRecepSalireId?: string, numComptSalaire?: string, shopId?: string, 
        dossierGalId?: string, cassierJudiciaire?: string, idTypePieceIdentite?: string, numPieceIdentite?: string,
        pieceIdentite?: string ) {
            this.id = id;
            this.adresseAgent = adresseAgent;
            this.boutique = boutique;
            this.contactAgent = contactAgent;
            this.dateNaisAgent = dateNaisAgent;
            this.droit = droit;
            this.nomAgent = nomAgent;
            this.prenomAgent = prenomAgent;
            this.profil = profil;
            this.pseudoAgent = pseudoAgent;
            this.sexeAgent = sexeAgent;
            this.passwordAgent = passwordAgent;
            this.photoProfil = photoProfil;

            this.codeAgent = codeAgent;
            this.paysAgent = paysAgent;
            this.contactMobile = contactMobile;
            this.contactFixe = contactFixe;
            this.dteNais = dteNais;
            this.dteDebService = dteDebService;
            this.dteFinService = dteFinService;
            this.idTypeAgent = idTypeAgent;
            this.actif = actif;
            this.enssured = enssured;
            this.newsletter = newsletter;
            this.methodRecepSalireId = methodRecepSalireId;
            this.numComptSalaire = numComptSalaire;
            this.shopId = shopId;
            this.dossierGalId = dossierGalId;
            this.cassierJudiciaire = idTypePieceIdentite;
            this.numPieceIdentite = numPieceIdentite;
            this.pieceIdentite = pieceIdentite;
    }

    
 

  }