 
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AdminService from './service';

import { FC, useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as yup from 'yup';


//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store';
import { setUser } from '../../Redux/Reducers/userReducer'; 

enum Accreditation {
    ADMIN,
    SUPERADMIN 
  }

interface AdminFormData {
    id?: string | null;
    firstname: string;
    lastname: string;
    email: string;
    emailConfirmation: string;
    username: string;
    country: string;
    accreditation: Accreditation

}

const AdminList: FC = () => {

    let idToModInit: any = null;
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.users.user );

    const formRef = useRef< FormikProps< AdminFormData >>(null);

    const adminInit: any = null
    const [ admin, setAdmin] = useState( adminInit );
    
    const [ admins, setAdmins] = useState( [] );
    const [key, setKey] = useState('all');
    const [ filteredAgents, setFilteredAgents ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const [ resetPasswordLoading, setResetPasswordLoading] = useState(false);

    const adminService = new AdminService();

    let [ countriesLoading, setCountriesLoading ] = useState(false);
    let [ countries, setCountries ] = useState([]);

    const [ passwordInputType, setPasswordInputType ] = useState('password');
    const [ passwordInputIcon, setPasswordInputIcon ] = useState('eye');

    const [ passwordConfirmInputType, setPasswordConfirmInputType ] = useState('password');
    const [ passwordConfirmInputIcon, setPasswordConfirmInputIcon ] = useState('eye');

    const accrInit: any = [];
    let [ accreditation, setAccreditation ] = useState(accrInit);

    const [keys, setKeys] = useState([
        {
            key: 'all',
            label: `${t('Tout')}` 
        },
        {
            key: 'fulltime',
            label: `${t('Temps plein')}`
        },
        {
            key: 'season',
            label: `${t('Saisonières')}`
        },
        {
            key: 'contract',
            label: `${t('Contractuels')}`
        },
        {
            key: 'stage',
            label: `${t('Stagiaires')}`
        }
    ]);

    const navigate = useNavigate();

    let editMember = (id: Number): void => {
        
    }

    const getAdmins = () => {
        setLoading(true);
        adminService.getAdmins().then(async function (response: any) {
            console.log(response);
            setAdmins(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getCountries = () => {
        setCountriesLoading(true);
        adminService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data.countries);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }

 

    useEffect( function() { 
        getAdmins();
        getCountries();

        if (user?.role === 'ROOT') {
            setAccreditation([
                'ADMIN',
                'SUPERADMIN'
            ])
        } else if (user?.role === 'SUPERADMIN') {
            setAccreditation([
                'ADMIN' 
            ])
        }
    }, []);

    const columns = [
        {
            name: `${t('Prénom')}`,
            selector: (row: any) => row.firstname,
        },
        {
            name: `${t('Nom')}`,
            selector: (row: any) => row.lastname,
        },
        {
            name: `${t('Email')}`,
            selector: (row: any) => row.email,
        },
        {
            name: `${t('Date d\'inscritpion')}`,
            selector: (row: any) => <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {row.created_at}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                        if (formRef.current !== null) {
                            formRef.current.values.id = row.id ;
                            formRef.current.values.firstname = row.firstname;
                            formRef.current.values.lastname = row.lastname;
                            formRef.current.values.email = row.email;
                            formRef.current.values.emailConfirmation = row.email;
                            formRef.current.values.username = row.username;
                            formRef.current.values.country = row.country;
                            formRef.current.values.accreditation = row.accreditation;
                            setAdmin(row);
                        }
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <i className='fas fa-edit'></i>
                            {
                                loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button>
                    
                    <button type="button" onClick={ (event) => {} }
                    className="btn waves-effect waves-light btn-danger">
                        <i className='fas fa-trash'></i>
                        {
                            loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                </button>
                </>
            )
        }
    ];
    
    const reload = () => {
        getAdmins();
    }

    const conditionalRowStyles = [
        {
          when: (row: any) => row.validated_at === null ||  row.validated_at === '',
          style: {
            backgroundColor: '#eac2c2',
            color: 'black',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => reload() }
                                            className="btn waves-effect waves-light btn-danger">
                                                { t('Actualiser') }
                                        </button>
                                        {
                                            admin !== null && <button  onClick={() => {
                                                setResetPasswordLoading(true);
                                                adminService.updateAdminPassword({
                                                    'id': admin?.id
                                                }).then(async function (response: any) {
                                                    console.log(response); 
                                                    if (formRef.current !== null) {
                                                        setAdmin(null);
                                                        formRef.current.values.id = null;
                                                        formRef.current.values.firstname = '';
                                                        formRef.current.values.lastname = '';
                                                        formRef.current.values.email = '';
                                                        formRef.current.values.emailConfirmation = '';
                                                        formRef.current.values.username = '';
                                                        formRef.current.values.country ='';
                                                        formRef.current.values.accreditation = Accreditation.ADMIN;
                                                        
                                                    }
                                                    alert("Mot de passe réinitialisé avec succès.");
                                                    setResetPasswordLoading(false); 
                                                })
                                                  .catch(function (error: any) {
                                                    console.log(error);
                                                    setResetPasswordLoading(false); 
                                                });

                                            }}  className='btn btn-warning waves-effect waves-light'>
                                            Réinitialiser mot de passe
                                            {
                                                resetPasswordLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                 </div>
                                            }
                                            </button> 
                                        }
                                    </div>

                                    <br />
                                    <br /> 

                                    <div className='row'>
                                        <div className='col-md-5' >
                                       
                                        <Formik
                                            innerRef={formRef}
                                            initialValues={{
                                                id: null,
                                                firstname: '',
                                                lastname: '',
                                                email: '',
                                                emailConfirmation: '',
                                                username: '',
                                                country: '',
                                                accreditation: Accreditation.ADMIN
                                            }}
                                            validationSchema={
                                                yup.object().shape({
                                                    country: yup
                                                        .string() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        ),
                                                    firstname: yup
                                                        .string() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        ),
                                                    email: yup
                                                        .string() 
                                                        .email('Email incorrect')
                                                        .required('Ce champ est obligatoire')
                                                        .test('checkEmailUnique', "Cet email a été déjà utilisé", value =>
                                                            adminService.verifyEmail(value ?? '')
                                                            .then((res) => { 
                                                                console.log(res);
                                                                return admin === null ?  res.data.email === null ? true : false : 
                                                                res.data.email === admin?.email ? true : false ;
                                                            })
                                                            .catch((e) => {
                                                                console.log(e);
                                                                return false;
                                                            })
                                                        ),
                                                    emailConfirmation: yup.string()
                                                        .oneOf([yup.ref('email'), null], 'Emails must match')
                                                        .required(`${'Ce champ est obligatoire'}`),
                                                    lastname: yup
                                                        .string() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        ),
                                                    username: yup
                                                        .string() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        )
                                                        .test('checkUsernameUnique', "Ce nom d\'utilisateur a été déjà utilisé", value =>
                                                            adminService.verifyUsername(value ?? '')
                                                            .then((res) => { 
                                                                console.log(res);
                                                                return admin === null ?  res.data.username === null ? true : false : 
                                                                res.data.username === admin?.username ? true : false ;
                                                            })
                                                            .catch((e) => {
                                                                console.log(e);
                                                                return false;
                                                            })
                                                        ),
                                                    accreditation: yup
                                                        .mixed().oneOf(['ADMIN', 'SUPERADMIN'])
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        )  
                                                })
                                               
                                            }
                                            onReset={() => { 
                                                if (formRef.current !== null) {
                                                    setAdmin(null);
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.firstname = '';
                                                    formRef.current.values.lastname = '';
                                                    formRef.current.values.email = '';
                                                    formRef.current.values.emailConfirmation = '';
                                                    formRef.current.values.username = '';
                                                    formRef.current.values.country ='';
                                                    formRef.current.values.accreditation = Accreditation.ADMIN;
                                                    
                                                }
                                            }}
                                            onSubmit={async (
                                                values ,
                                                actions
                                            ) => {
                                                console.log(values);
                                                setLoading(true);

                                                if (values.id !== null) {

                                                    adminService.updateAdmin(values).then(async function (response: any) {
                                                        console.log(response);
                                                        alert("Compte admin mis à jour avec succès.");
                                                        setAdmin(null);
                                                        actions.resetForm({
                                                            values: {
                                                                id: null,
                                                                firstname: '',
                                                                lastname: '',
                                                                email: '',
                                                                emailConfirmation: '',
                                                                username: '',
                                                                country: '',
                                                                accreditation: Accreditation.ADMIN
                                                            }
                                                        });
                                                        getAdmins(); 
                                                        setLoading(false); 
                                                    })
                                                      .catch(function (error: any) {
                                                        console.log(error);
                                                        setLoading(false); 
                                                    });

                                                } else {


                                                    adminService.createAdmin(values).then(async function (response: any) {
                                                        console.log(response);
                                                        alert("Compte admin créé");
                                                        getAdmins();
                                                        actions.resetForm();
                                                        setLoading(false); 
                                                    })
                                                      .catch(function (error: any) {
                                                        console.log(error);
                                                        setLoading(false); 
                                                    });


                                                }
 
                                               
                                                
                                            }}
                                            >
                                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values, handleReset }) => (
                                            <Form>
                                            <div className="row">

                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="firstname">
                                                            {
                                                                t("Prénoms")
                                                            }
                                                        </label>
                                                        
                                                        <input className={`form-control ${ errors.firstname && touched.firstname ? 
                                                        "is-invalid":""}`}
                                                            id="firstname"
                                                            type="text"
                                                            name="firstname"
                                                            placeholder={ `${t("Prénoms")}` }
                                                            onChange={handleChange('firstname')}
                                                            onBlur={handleBlur('firstname')}
                                                            value={values.firstname}
                                                        />
                                                        
                                                        { errors.firstname && touched.firstname && errors.firstname && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.firstname && touched.firstname && errors.firstname }
                                                        </div> }
                                                        
                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="lastname">
                                                            {
                                                                t("Nom")
                                                            }
                                                        </label>
                                                        
                                                        <input className={`form-control ${ errors.lastname && touched.lastname ? 
                                                        "is-invalid":""}`}
                                                            id="lastname"
                                                            type="text"
                                                            name="lastname"
                                                            placeholder={ `${t("Nom")}` }
                                                            onChange={handleChange('lastname')}
                                                            onBlur={handleBlur('lastname')}
                                                            value={values.lastname}
                                                        />
                                                        
                                                        { errors.lastname && touched.lastname && errors.lastname && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.lastname && touched.lastname && errors.lastname }
                                                        </div> }
                                                        
                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="lastname">
                                                            {
                                                                t("Email")
                                                            }
                                                        </label>
                                                        
                                                        <input disabled={ admin !== null } className={`form-control ${ errors.email && touched.email ? 
                                                        "is-invalid":""}`}
                                                            id="email"
                                                            type="email"
                                                            name="email"
                                                            placeholder={ `${t("Email")}` }
                                                            onChange={handleChange('email')}
                                                            onBlur={handleBlur('email')}
                                                            value={values.email}
                                                        />
                                                        
                                                        { errors.email && touched.email && errors.email && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.email && touched.email && errors.email }
                                                        </div> }
                                                        
                                                    </div>
                                                </div>


                                                
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="lastname">
                                                            {
                                                                t("Email confirmation")
                                                            }
                                                        </label>
                                                                    <input disabled={ admin !== null } type="email" className={`form-control ${
                                                                         errors.emailConfirmation && touched.emailConfirmation ? "is-invalid":""}`}
                                                                        id="emailConfirmation" 
                                                                        name="emailConfirmation"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('emailConfirmation')(event); 
                                                                         }}
                                                                        onBlur={handleBlur('emailConfirmation')}
                                                                        value={values.emailConfirmation == null ? ''  :
                                                                        values.emailConfirmation }
                                                                        placeholder="Email confirmation" />
                                                                        { errors.emailConfirmation && touched.emailConfirmation &&
                                                                         errors.emailConfirmation && 
                                                                        <div id="validationServer05Feedback" 
                                                                            className="invalid-feedback">
                                                                            { errors.emailConfirmation.toString() }
                                                                        </div> }
                                                    </div>
                                                </div>



                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="pseudo">
                                                            {
                                                                t("Nom d'utilisateur")
                                                            }
                                                        </label>
                                                        
                                                        <input disabled={ admin !== null } className={`form-control ${ errors.username && touched.username ? "is-invalid":""}`}
                                                            id="username"
                                                            type="text"
                                                            name="username"
                                                            placeholder={ `${t("Nom d'utilisateur")}` }
                                                            onChange={handleChange('username')}
                                                            onBlur={handleBlur('username')}
                                                            value={values.username}
                                                        />
                                                        
                                                        { errors.username && touched.username && errors.username && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.username && touched.username && errors.username }
                                                        </div> }
                                                        
                                                    </div>
                                                </div> 


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor="">Accréditation</label>
                                                        
                                                        <select className={`form-control custom-select 
                                                                                ${ errors.accreditation &&
                                                                                            touched.accreditation ? "is-invalid" : "" }`}
                                                                                        id="accreditation" 
                                                                                        name="accreditation"
                                                                                        onChange={event => { 
                                                                                            handleChange('accreditation')(event); 
                                                                                        }}
                                                                                        onBlur={handleBlur('accreditation')}
                                                                    value={values.accreditation == null ? '' : values.accreditation}
                                                                                        placeholder="Accréditation" >
                                                                <option value="">Accréditation</option>

                                                                {
                                                                    accreditation.map((acc: any, index: number) => <option key={index}
                                                                    value={ acc }>{ acc }</option>)
                                                                } 
                                                        </select>  

                                                        { 
                                                            errors.accreditation && touched.accreditation && errors.accreditation && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.accreditation && touched.accreditation && errors.accreditation }
                                                            </div> 
                                                        }

                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor="">Pays d'action</label>
                                                        {   countriesLoading ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                <span className="sr-only">Loading...</span>
                                                                            </div> : <select className={`form-control custom-select 
                                                                            ${ errors.country &&
                                                                                            touched.country ? "is-invalid" : "" }`}
                                                                                        id="country" 
                                                                                        name="country"
                                                                                        onChange={event => { 
                                                                                            handleChange('country')(event); 
                                                                                        }}
                                                                                        onBlur={handleBlur('country')}
                                                                                        value={values.country == null ? '' : values.country}
                                                                                        placeholder="Pays d'origine" >
                                                            <option value="">Pays d'action</option>

                                                            {
                                                                countries.map((country: any, index: number) => <option key={index}
                                                                 value={ country.nicename }>{ country?.nicename }</option>)
                                                            } 
                                                        </select> }

                                                        { errors.country && touched.country && errors.country && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.country && touched.country && errors.country }
                                                        </div> }

                                                    </div>
                                                </div> 
                                                   
                                                <div className="col-lg-12 btn-list">
                                                    <button type="submit" 
                                                        // loading={loading}
                                                        // disabled={!isValid} 
                                                        disabled={!dirty}
                                                        // onClick={event =>  handleSubmit}
                                                        className="btn btn-lg btn-primary btn-connexion" >
                                                        {
                                                             admin === null ? t('Créer') : t('Modifier')
                                                        }
                                                        {
                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </button>

                                                    <button type="button" 
                                                         
                                                        onClick={ handleReset }
                                                        className="btn btn-lg btn-danger btn-connexion" >
                                                        Annuler
                                                        {
                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                                {/* <div className="col-lg-12 text-center mt-5">
                                                    Don't have an account? <a href="#" className="text-danger">Sign Up</a>
                                                </div> */}
                                            </div>
                                            </Form>
                                        )}
                                    </Formik>

                                            
                                        </div>
                                        <div className='col-md-7' >

                                        <DataTable
                                                            responsive={true}
                                                            className="table table-striped table-bordered"
                                                            columns={columns}
                                                            data={admins}
                                                            progressPending={loading}
                                                            conditionalRowStyles={conditionalRowStyles}
                                                            pagination 
                                                        />

                                        </div>
                                    </div> 
                                   
                                    
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default AdminList