import User from '../../Models/User';
import Member from '../../Models/Member';
import axiosInstance from '../../Utils/axios_manager';

export default class AdminService {
  
    constructor() {  }

    public getAdmins =  async () => {
        return await axiosInstance.get('/getAdmins.php');
    }

    public getCountries =  async () => {
        return await axiosInstance.get('/get_countries.php');
    }

    public verifyEmail =  async (email: string) => {
        return await axiosInstance.get('/verify_admin_email.php' + `?email=${email}`);
    }

    public verifyUsername =  async (username: string) => {
        return await axiosInstance.get('/verify_admin_username.php' + `?username=${username}`);
    }

    public createAdmin = async (data: Record<string, any>) => {
        return await axiosInstance.post('/create_admin.php', data);
    }

    public updateAdmin = async (data: Record<string, any>) => {
        return await axiosInstance.post('/update_admin.php', data);
    }

    public updateAdminPassword = async (data: Record<string, any>) => {
        return await axiosInstance.post('/reset_admin_password.php', data);
    }
    

}