import { FC } from 'react' 
  
const Footer: FC = () => {  
    return (  
      <footer className="footer text-center text-muted">
          All Rights Reserved by <a
              href="https://wrappixel.com">AAIC - GLOBAL</a>.
      </footer>
    )   
}
    
export default Footer