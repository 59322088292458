import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {  ChatState } from '../States/states';
import { RootState } from '../store';


const initialState: ChatState = {
  messages: [],
  loadMessages: true
};


export const chatSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction< any[]  >) => {
        console.log("Set Messages");
        state.messages = action.payload;
    },
    toggleLoadMessages: (state, action: PayloadAction< null >) => { 
      state.loadMessages = !state.loadMessages;
  },
  },
})

// Action creators are generated for each case reducer function
export const { setMessages, toggleLoadMessages } = chatSlice.actions;

// Other code such as selectors can use the imported `RouteState` type
export const getMessages = (state: RootState ) => state.chats.messages

export default chatSlice.reducer