import { FC, useEffect, useState } from 'react'
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import MemberForm from './form';
import MemberService from './service';

//Alert
import Alert from '../../Components/Alert';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store';
import { useTranslation } from 'react-i18next';
import Member from '../../Models/Member';

//Moment react
import Moment from 'react-moment';

const MemberEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const memberService = new MemberService();
    const user = useAppSelector((state: RootState) => state.users.user );
    let { id } = useParams();
    let initialMember: any;
    const [member, setMember] = useState(initialMember);
    const [loading, setLoading] = useState(false);

    let [ rootOrSuperAdmin, setRootOrSuperAdmin ] = useState(false);
    let [ isAdmin, setIsAdmin ] = useState(false);

    const [validateLoading, setValidateLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        memberService.getMember(Number(id)).then(async function (response: any) {
            console.log(response); 
            const memberFormatted = response.data;
            setMember(memberFormatted);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    useEffect(() => { 
       setRootOrSuperAdmin(() => (user?.role === 'ROOT' || user?.role === 'SUPERADMIN'));
       setIsAdmin(() => (user?.role === 'ADMIN'));
    }, [user])

    const validateMember = () => {
        // const confirmation = window.confirm( `${ t("Vous êtes sûre de continuer ?") }` );
        setValidateLoading(true);
        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            type: 'error',
                            title: 'Confirmation',
                            message: 'Êtes vous sûre de continuer l\'action ?',
                            actionText: 'Continuer',
                            action: () => {

                                memberService.validateMember( member?.id ).then(async function (response: any) {
                                    console.log(response);  
                                    setValidateLoading(false); 
                                    onClose();
                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        type: 'success',
                                                        title: 'Succès',
                                                        message: 'Le membre a été validé avec succès',
                                                        actionText: 'Ok',
                                                        action: () => { onClose(); window.location.reload(); } 
                                                    }}
                                                />
                                          )}});
                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setValidateLoading(false); 
                                    onClose();
                                });
                                
                              
                            },
                            cancelText: 'Annuler',
                            cancelAction: () => {
                                setValidateLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

 
    }

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t("Modifier un member") }
                                </h4>
                                <div className="btn-list">
                                    <Link type="button" to="/member/list"
                                        className="btn waves-effect waves-light btn-danger">
                                        { t("Retour") }    
                                    </Link>
                                    {  member !== null && member !== undefined  ?
                                        (   isAdmin && (member.validated_at === null || member.validated_at === '' ) && 
                                            (member.validated_by === null || member.validated_by === '')  
                                        ) || (
                                         rootOrSuperAdmin && 
                                        (member['2_validated_at'] === null || member['2_validated_at'] === '' ) && 
                                        (member['2_validated_by'] === null || member['2_validated_by'] === '')
                                        && !(member.validated_at === null || member.validated_at === '' ) && 
                                            !(member.validated_by === null || member.validated_by === '')
                                       )  ?
                                    <button type="button" onClick={ validateMember }
                                        className="btn waves-effect waves-light btn-warning">
                                          { t('Valider le membre') } {
                                            validateLoading && 
                                            <div className="spinner-border text-primary spinner-border-lg" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                          } 
                                    </button> : <></> : <></> } 
                                    {/* <button type="button" onClick={ fireAgent }
                                        className="btn waves-effect waves-light btn-danger">
                                        { t("Licencier") }      
                                    </button> */}
                                   
                                </div>
                                <br />
                                { !loading && member ? 
                                <>
                                <p> <b>Membre créé:</b> {<Moment format="DD/MM/YYYY hh:mm:ss">
                                        {member.created_at}
                                    </Moment> } </p>
                                { member.updated_at !== null && member.updated_at !== '' ? <p > <b>Dernière mise à jour:</b> { <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {member.updated_at}
                                    </Moment> } </p> : <></> }
                                
                                    <p > <b>Date de première validation:</b> {member.validated_at !== null && member.validated_at !== '' ? <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {member.validated_at }
                                    </Moment> : t('Client non validé')} </p> 

                                    <p > <b>Validé par :</b> {member.validated_by !== null && member.validated_by !== '' ? 
                                    member.validated_by.firstname + " " + member.validated_by.lastname : t('Client non validé')} </p>

                                    {
                                        user?.role === "ROOT" || user?.role === "SUPERADMIN" &&
                                        <p > <b>Date de deuxième validation:</b> {member['2_validated_at'] !== null && member['2_validated_at'] !== '' ? <Moment format="DD/MM/YYYY hh:mm:ss">
                                            {member['2_validated_at'] }
                                        </Moment> : t('Client non validé')} </p>
                                    }

                                    {
                                        user?.role === "ROOT" || user?.role === "SUPERADMIN" &&
                                        <p > <b>Validé par :</b> {member['2_validated_by'] !== null && member['2_validated_by'] !== '' ?
                                            member['2_validated_by'].firstname + " " + member['2_validated_by'].lastname : t('Client non validé')} </p>
                                    }

                                </> : <></>    }
                                
                                <hr />

                                { !loading && member ?
                                    <MemberForm state='edit' member={ member } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default MemberEdit