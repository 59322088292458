import {
  Menu, MenuItem
} from '../Models/Menu';

import { faHome, faUsers } from "@fortawesome/free-solid-svg-icons";

const menu: Menu = new Menu([
  {
    id: 1,
    name: "ACCUEIL",
    link: "/",
    icon: faHome,
    subMenu: [],
    permissions: []
  },
  {
    id: 2,
    name: "MEMBRES",
    link: "",
    icon: faUsers,
    subMenu: [ 
      {
        id: 2,
        name: "liste",
        link: "/member",
        icon: faUsers,
        subMenu: [],
        permissions: [ ]
      },
      {
        id: 2,
        name: "A valider",
        link: "/member/waiting-delivery",
        icon: faUsers,
        subMenu: [],
        permissions: [ 'ROOT', 'SUPERADMIN' ]
      }
     ],
    permissions: [  ]
  },
  {
    id: 3,
    name: "ADMINISTRATEURS",
    link: "",
    icon: faUsers,
    subMenu: [
      {
        id: 3,
        name: "liste",
        link: "/admin",
        icon: faUsers,
        subMenu: [],
        permissions: [ 'ROOT', 'SUPERADMIN' ]
      }
    ],
    permissions: [ 'ROOT', 'SUPERADMIN' ]
  }
]);

export default menu