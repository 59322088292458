import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { AlertState } from '../States/states';


const initialState: AlertState = {
    title: "",
    message: "",
    action: () => {},
    type: 'success',
    actionText: 'Ok',
    effecter: false
};


export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertData : (state, action: PayloadAction<  AlertState  >) => {
        console.log("Setting alert data");
        state.effecter = !state.effecter;
        // state = action.payload;
    }, 
  },
})

// Action creators are generated for each case reducer function
export const { setAlertData } = alertSlice.actions;

// Other code such as selectors can use the imported `RouteState` type
// export const selectRoute = (state: RootState) => state.alerts.
export default alertSlice.reducer