import { FC } from 'react'
import Header from '../Layouts/Header'; 
import Footer from '../Layouts/Footer';


const NotFound: FC = () => {  
 
        return (
            <div>
                <Header activeMenu={''} />
                    <main id="content">
                        <section className="pt-lg-18 pb-lg-17 py-14 bg-img-cover-center"
                         style={{ backgroundImage: "url('./assets/images/bg-slider-11.jpg')"}}>
                            <div className="container pt-lg-8 pb-lg-7">
                            <p className="text-secondary mb-5 text-uppercase font-weight-600 letter-spacing-015" 
                            data-animate="fadeInUp">Page not found</p>
                            <h1 className="fs-44 fs-lg-56 lh-121" data-animate="fadeInUp">404</h1>
                            </div>
                        </section>
                    </main>

                <Footer />
            </div>
        );
}

export default NotFound