import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// // import the original type declarations
import "i18next";
 
import en from "../locales/en.json";
import fr from "../locales/fr.json"; 

const defaultNS = "fr";
const resources = {
  en: {
    translation: {
      ...en,
      menu: '{{menuName}}',
      submenu: '{{subMenuName}}',
      agentTabLabel: '{{agentTabLabelName}}',
      alertTitle: '{{alertTitleText}}',
      alertMessage: '{{alertMessageText}}',
      alertOk: '{{alertOkText}}',
      alertCancel: '{{alertCancelText}}',
      analyticPeriod: '{{ analyticPeriodName }}',
    }
  },
  fr: {
    translation: {
      ...fr,
      menu: '{{menuName}}',
      submenu: '{{subMenuName}}',
      agentTabLabel: '{{agentTabLabelName}}',
      alertTitle: '{{alertTitleText}}',
      alertMessage: '{{alertMessageText}}',
      alertOk: '{{alertOkText}}',
      alertCancel: '{{alertCancelText}}',
      analyticPeriod: '{{ analyticPeriodName }}',
    }
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "fr",
  // ns: ["fr", "en"],
  // defaultNS,
  fallbackLng: 'fr',
  // debug: true,
  resources,
  interpolation: {
    escapeValue: false 
  }
});

export default i18n;

// import "i18next";
// export const defaultNS = "fr";
// export const resources = {
//   en: en,
//   fr: fr
// } as const;

// i18n.use(initReactI18next).init({
//   lng: "fr",
//   ns: ["fr", "en"],
//   defaultNS,
//   resources,
//   debug: true,
// });
