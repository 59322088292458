import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {  LangState } from '../States/states'; 
import { RootState } from '../store';


const initialState: LangState = {
  lang: 'fr'
};


export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction< string >) => {
        console.log("SET LANG");
        window.localStorage.setItem('interface_lang', action.payload)
        state.lang = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLang } = langSlice.actions;

// Other code such as selectors can use the imported `RouteState` type
export const getLang = (state: RootState ) => state.users.user

export default langSlice.reducer