export default class Admin {
    public id?: number;
    public firstname?: string;
    public lastname?: string; 
    public role?: string;
    public token?: string;
    public email?: string;
    public username?: string;


    static fromJson(parsedJson: Record<string, any>): Admin {
        return new Admin(
            Number(parsedJson['id']),
            parsedJson['firstname'],
            parsedJson['lastname'],
            parsedJson['role'],
            parsedJson['token'],
            parsedJson['email'],
            parsedJson['username'],
        );
    }

    static toJson(user: Admin): Record<string, any>  {
        return  {
            "id": user.id,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "role": user.role,
            "token": user.token,
            "email": user.email,
            "username": user.username
        }
    }
  
    constructor(id: number, firstname: string, lastname: string, role: string, token: string, email: string, username: string ) {
            this.id = id;
            this.firstname = firstname;
            this.lastname = lastname;
            this.role = role;
            this.token = token;
            this.email = email;
            this.username = username
    }

  }