import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export class MenuItem {
    public  id: number;
    public name: string;
    public icon: IconDefinition;
    public link: string;
    public subMenu: Array<MenuItem>;
    public permissions: Array<string>;
  
    constructor(id: number, name: string, icon: IconDefinition, link: string, subMenu: Array<MenuItem> = [] , permissions: Array<string> = []) {
      this.id = id;
      this.name = name;
      this.icon = icon;
      this.link = link;
      this.subMenu = subMenu;
      this.permissions = permissions
    }
  

}

export class Menu {
    public  menu: Array<MenuItem>;
  
    constructor(menu: Array<MenuItem>) {
      this.menu = menu;
    }
}
