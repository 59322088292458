import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import MemberService from './service';

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const MemberList: FC = () => {  
    let idToModInit: any = null;
    const { t } = useTranslation();
    const [ members, setMembers ] = useState( [] );
    const [key, setKey] = useState('all');
    const [ filteredAgents, setFilteredAgents ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const memberService = new MemberService();

    const [keys, setKeys] = useState([
        {
            key: 'all',
            label: `${t('Tout')}` 
        },
        {
            key: 'fulltime',
            label: `${t('Temps plein')}`
        },
        {
            key: 'season',
            label: `${t('Saisonières')}`
        },
        {
            key: 'contract',
            label: `${t('Contractuels')}`
        },
        {
            key: 'stage',
            label: `${t('Stagiaires')}`
        }
    ]);

    const navigate = useNavigate();

    let editMember = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/member/edit/'+id.toString());
        setLoading(false);
    }

    const getMembers = () => {
        setLoading(true);
        memberService.getMembers().then(async function (response: any) {
            console.log(response);
            setMembers(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

 

    useEffect( function() { 
        getMembers();
    }, []);

    const columns = [
        {
            name: `${t('Prénom')}`,
            selector: (row: any) => row.firstname,
        },
        {
            name: `${t('Nom')}`,
            selector: (row: any) => row.lastname,
        },
        {
            name: `${t('Email')}`,
            selector: (row: any) => row.email,
        },
        {
            name: `${t('Date d\'inscritpion')}`,
            selector: (row: any) => <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {row.created_at}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => editMember(Number(row.id))}
                        className="btn waves-effect waves-light btn-success">
                            <i className='fas fa-edit'></i>
                            {
                                loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button>
                    
                    <button type="button" onClick={ (event) => {} }
                    className="btn waves-effect waves-light btn-danger">
                        <i className='fas fa-trash'></i>
                        {
                            loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                </button>
                </>
            )
        }
    ];
    
    const reload = () => {
        getMembers();
    }

    const conditionalRowStyles = [
        {
          when: (row: any) => row.validated_at === null ||  row.validated_at === '',
          style: {
            backgroundColor: '#eac2c2',
            color: 'black',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => reload() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        {/* <Link type="button" to="/agent/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter un agent') }    
                                        </Link> */}
                                    </div>
                                    
                                    <br />
                                    <br />
                                    <DataTable
                                                            responsive={true}
                                                            className="table table-striped table-bordered"
                                                            columns={columns}
                                                            data={members}
                                                            progressPending={loading}
                                                            conditionalRowStyles={conditionalRowStyles}
                                                            pagination 
                                                        />
                                    
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default MemberList