import { FC, useEffect } from 'react'
import {
    Outlet
} from "react-router-dom";

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../Hooks/customSelector';
import { RootState } from '../Redux/store';
import { setLang } from '../Redux/Reducers/langReducer';

const Auth: FC = () => { 
    
    const { t, i18n } = useTranslation();
    const lang = useAppSelector((state: RootState) => state.lang.lang );
    const dispatch = useAppDispatch();

    useEffect(() => { 

        if (lang !== null && lang !== undefined) {
            i18n.changeLanguage(lang).then(
                (res) => {
                    dispatch( setLang(lang)  ); 
                }
            );
        }

        const stored_lang = window.localStorage.getItem('interface_lang');

        if (stored_lang !== null && stored_lang !== undefined) {
            i18n.changeLanguage(stored_lang).then(
                (res) => {
                    dispatch( setLang(stored_lang)  ); 
                }
            );
        }

    }, [])

    return (
        <div className="main-wrapper">
            <div className="preloader">
                <div className="lds-ripple">
                    <div className="lds-pos"></div>
                    <div className="lds-pos"></div>
                </div>
            </div>

            <Outlet />
            
         </div>   
    );
}

export default Auth