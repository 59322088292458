import { FC } from 'react'; 
 
import { useAppSelector } from '../Hooks/customSelector';
import { RootState } from '../Redux/store';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Breadcrumb: FC = () => {

    const routes = useAppSelector((state) => state.routes);
    const user = useAppSelector((state: RootState) => state.users.user );

    const { t, i18n } = useTranslation();
      
        return ( 
            <>
            {/* <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-7 align-self-center">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                            {
                                routes.currentRoute[routes.currentRoute.length - 1 ] == '' ? 
                                <>{t('Bonjour')} { user?.prenomAgent + " " + user?.nomAgent }!</> : 
                               <span style={{textTransform: "uppercase"}}>{ routes.currentRoute[1] + ' ' + ( routes.currentRoute.length > 2 ? 
                                routes.currentRoute[2] : '' ) }</span>
                            }
                            
                        </h3>
                        <div className="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0 p-0">
                                    <li className="breadcrumb-item"><Link to="/">
                                        { t('Accueil') }</Link>
                                    </li>
                                    {
                                        routes.currentRoute.map(
                                            (path, id) => {
                                                if (path === "") return null;
                                                return <li key={id} className="breadcrumb-item text-muted active" 
                                                    aria-current="page">
                                                    {path}
                                                    </li>
                                                        
                                                }
                                        )
                                    }
                                    
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-5 align-self-center">
                        <div className="customize-input float-right"> 
                        </div>
                    </div>
                </div>
            </div>  */}
     
            </>
        )
}

export default Breadcrumb