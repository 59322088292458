
import { configureStore } from '@reduxjs/toolkit'
import alertReducer from './Reducers/alertReducer';
import chatReducer from './Reducers/chatReducer';
// ...
import routeReducer from './Reducers/routeReducer';
import userReducer from './Reducers/userReducer';
import langReducer from './Reducers/langReducer';


const store = configureStore({
  reducer: {
    routes: routeReducer,
    users: userReducer,
    alerts: alertReducer,
    chats: chatReducer,
    lang: langReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;