import User from '../../Models/User';
import Member from '../../Models/Member';
import axiosInstance from '../../Utils/axios_manager';

export default class MemberService {
  
    constructor() {  }

    public getMembers =  async () => {
        return await axiosInstance.get('/getMembers.php');
    }

    public getMember =  async (id: Number) => {
        return await axiosInstance.get('/getMember.php' + `?id=${id.toString()}`);
    }

    public postAgent =  (user: User) => {
        return axiosInstance.post('/agent/add', User.toJson(user));
    }

    public getAgents =  async () => {
        return await axiosInstance.get('/agent/list');
    }

    public getAgent =  async (id: Number) => {
        return await axiosInstance.get('/agent/details' + `?id=${id.toString()}`);
    }

    public putAgent =  (user: User) => {
        return axiosInstance.post('/agent/update', User.toJson(user));
    }

    public putMember =  (member: Record<string, any>) => {
        return axiosInstance.post('/updateMember.php', member);
    }

    public getAgentTypeList =  async () => {
        return await axiosInstance.get('/agent/type/list');
    }

    public getTransactionsMethods =  async () => {
        return await axiosInstance.get('/transanctions/methods/list');
    }

    public uploadAgentFile =  (file: any) => {
        const body = {'fileContent': file}; 

        return axiosInstance.post('/uploads/agent', body);
    }

    public uploadAgentId =  (file: any) => {
        const body = {'fileContent': file}; 

        return axiosInstance.post('/uploads/agent', body);
    }

    public disableAgent =  async (data: Record<string, any>) => {
        return axiosInstance.post('/agent/desable', data);
    }

    public fireAgent =  async (data: Record<string, any>) => {
        return axiosInstance.post('/agent/fired', data);
    }

    public getPieceTypes =  async () => {
        return await axiosInstance.get('/pieces/type/list');
    } 

    public getFullTimeAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=1');
    }  

    public getSeasonAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=2');
    } 
    
    public getContractAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=3');
    }

    public getStageAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=4');
    }

    public verifyEmail =  async (email: string) => {
        return await axiosInstance.get('/verify_email.php' + `?email=${email}`);
    }

    public verifyPhoneNumber =  async (email: string) => {
        return await axiosInstance.get('/verify_phone_number.php' + `?phone_number=${email}`);
    }

    public getCountries =  async () => {
        return await axiosInstance.get('/get_countries.php');
    }

    public validateMember = (id: string) => {
        return axiosInstance.post('/validate_member.php', { id: id });
    }

    public getWaitingValidationMembers =  async () => {
        return await axiosInstance.get('/getWaitingValidationMembers.php');
    }

}